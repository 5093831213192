.Form {
  display: flex;
  flex-direction: column;
  gap: 40px;

  position: relative;

  width: 90%;
  max-width: 800px;
  box-sizing: border-box;
  padding: 80px;
  margin: 40px 0;
  background-color: #FFF;

  font-size: 20px;
  font-family: sans-serif;

  box-shadow: 0 0 0 2px #888;
}

.Form .Answers {
  margin-top: 10px;
}

.Form .Answers label {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;

  padding: 5px 0;

  user-select: none;
}

.Form .Answers input {
  width: fit-content;
  margin: auto;

  transform: scale(1.5);
}

.Form .Answers input:focus-visible {
  box-shadow: 0 0 0 2px white, 0 0 0 4px #7BA9FE;
  outline: none;
}

.Form .goBack {
  position: absolute;
  top: 25px;
  left: 25px;

  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: #FFF url(arrow_left.png) no-repeat center / 30px 30px;

  font-size: 30px;
  box-shadow: 0 0 0 2px #000;
}

.Form .goBack:hover {
  background-color: #DDD;
}

.Form .goBack:focus {
  box-shadow: 0 0 0 2px #000, 0 0 0 4px #fff, 0 0 0 6px #000;
}

.Form .goBack:focus-visible {
  outline: none;
}

.Form .SubmitButton {
  display: block;

  width: fit-content;
  padding: 15px;
  margin: 0 auto;
  border: none;
  background-color: #7BA9FE;

  font-weight: bold;
  font-size: 20px;
}

.Form .SubmitButton:hover {
  background-color: #6E98E6;
}

.Form .SubmitButton:focus {
  box-shadow: 0 0 0 3px #000;
}

.Form .SubmitButton:focus-visible {
  outline: none;
}

@media (max-width: 600px) {
  .Form {
    padding: 80px 20px 60px;
  }
}
