.UploadImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  position: relative;

  width: 90%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 60px 40px;
  background-color: #FFF;

  text-align: center;
  font-size: 20px;
  font-family: sans-serif;

  box-shadow: 0 0 0 2px #888;
}

.UploadImage input[type="file"] {
  position: absolute;
  z-index: -1;
}

.UploadImage input[type="file"]:focus + label {
  box-shadow: 0 0 0 3px #000;
}

.UploadImage label {
  display: block;

  padding: 15px;
  background-color: #7BA9FE;

  font-weight: bold;
}

.UploadImage label:hover {
  background-color: #6E98E6;
}

.UploadImage .goBack {
  position: absolute;
  top: 15px;
  left: 15px;

  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: #FFF url(arrow_left.png) no-repeat center / 30px 30px;

  font-size: 30px;
  box-shadow: 0 0 0 2px #000;
}

.UploadImage .goBack:hover {
  background-color: #DDD;
}

.UploadImage .goBack:focus {
  box-shadow: 0 0 0 2px #000, 0 0 0 4px #fff, 0 0 0 6px #000;
}

.UploadImage .goBack:focus-visible {
  outline: none;
}
