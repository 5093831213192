body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

body::-webkit-scrollbar {
  width: 20px;
}

body::-webkit-scrollbar-track {
  background: #EEE;
  box-shadow: 0 0 3px #000 inset;
}

body::-webkit-scrollbar-thumb {
  background: firebrick;
  border-radius: 10px;
}
