.Modal {
  display: grid;
  place-content: center;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.75);

  font-family: sans-serif;
}

.Card {
  width: 100%;
  max-width: 400px;
  background-color: #CCE9FF;
}

.Card form {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 20px;

  box-sizing: border-box;
  padding: 30px;

  font-size: 18px;
}

.Card > div {
  display: flex;
  justify-content: center;

  padding: 10px;
  background-color: #13A3B1;
  box-sizing: border-box;

  font-size: 25px;
  font-weight: bold;
  color: white;
}

.Card form > input {
  width: 100%;
  border: none;
  box-shadow: 0 0 2px 0 gray;

  font-size: 18px;
}

.Card button {
  grid-column: span 2;

  width: fit-content;
  padding: 10px;
  margin: auto;
  border: none;
  background-color: #7BA9FE;

  font-size: 20px;
  color: white;
}

.Card button:hover {
  background-color: #6A93DE;
}

.Card {
  position: relative;
  animation: modal 0.3s;
}

@keyframes modal {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
