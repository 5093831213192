.FormAnalysis {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  position: relative;

  width: 90%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 40px;
  background-color: #FFF;

  text-align: center;
  font-size: 20px;
  font-family: sans-serif;

  box-shadow: 0 0 0 2px #888;
}

.spinner {
  --spinner-color: #13A3B1;
  --spinner-top-color: #7ED7C4;
  --spinner-size: 80px;
  --spinner-border: 14px;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border-radius: 50%;
  border-top: var(--spinner-border) solid var(--spinner-top-color);
  border-right: var(--spinner-border) solid var(--spinner-color);
  border-bottom: var(--spinner-border) solid var(--spinner-color);
  border-left: var(--spinner-border) solid var(--spinner-color);
  animation: spinner 2s ease-in-out infinite;
}

.FormAnalysis .goBack {
  position: absolute;
  top: 15px;
  left: 15px;

  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: #FFF url(arrow_left.png) no-repeat center / 30px 30px;

  font-size: 30px;
  box-shadow: 0 0 0 2px #000;
}

.FormAnalysis .goBack:hover {
  background-color: #DDD;
 }

.FormAnalysis .goBack:focus {
  box-shadow: 0 0 0 2px #000, 0 0 0 4px #fff, 0 0 0 6px #000;
}

.FormAnalysis .goBack:focus-visible {
  outline: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.FormAnalysis img {
  width: 200px;
}
