.AccessButton {
  width: 100%;
  max-width: 210px;
  height: 60px;
  border: none;
  background-color: #FFF;

  font-size: 20px;
  font-weight: bold;

  box-shadow: 0 0 0 2px #888;
}

.AccessButton:hover {
  background-color: #DDD;
}

.AccessButton:focus {
  box-shadow: 0 0 0 3px #000;
}

.AccessButton:focus-visible {
  outline: none;
}
