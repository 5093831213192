.Main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  background-color: #cce9ff;
  background-image: url(circuit-board.svg);
}

.Buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 600px;
}

.MessageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 50px;

  width: 100%;
  max-width: 600px;
}

.Message {
  width: 300px;
  box-sizing: border-box;
  padding: 10px;
  background-color: #FFF;

  position: relative;

  text-align: center;
  font-size: 20px;

  box-shadow: 0 0 0 2px #888;
}

.Message::before {
  content: "";
  width: 0;
  height: 0;

  position: absolute;
  bottom: 0;
  left: -27px;

  border-top: 15px solid transparent;
  border-bottom: 15px solid #FFF;
  border-right: 15px solid #FFF;
  border-left: 15px solid transparent;

  filter: drop-shadow(-4px 2px 0 #888);
}

@media (max-width: 600px) {
  .Buttons {
    flex-direction: column;
    height: 300px;
  }

  .MessageContainer {
    gap: 30px;
  }

  .Message {
    width: 300px;
    box-sizing: border-box;
    padding: 10px;
    background-color: #FFF;

    position: relative;

    text-align: center;
    font-size: 20px;
  }
}

@media (max-height: 600px) {
  .MessageContainer {
    display: none;
  }
}
