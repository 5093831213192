.Header {
  display: flex;
  justify-content: center;
  font-family: sans-serif;

  position: sticky;
  top: 0;
  z-index: 1;

  background-color: #13A3B1;
}

.Header h1 {
  font-size: 30px;
  color: #FFFFFF;
  cursor: pointer;
}

.Header a {
  position: absolute;
  top: 50%;
  right: 10px;

  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding-top: 8px;
  box-sizing: border-box;
  background-color: white;
  background-image: url(manual.png);
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center 25px;

  text-align: center;
  text-decoration: none;
  font-size: 14px;
  color: black;

  transform: translate(0%,-50%);
}

.Header a:focus {
  box-shadow: 0 0 0 3px #000;
}

.Header a:focus-visible {
  outline: none;
}
