.EditProfile {
  display: grid;
  grid-template-columns: 102px 1fr;
  gap: 25px;

  position: relative;

  max-width: 400px;
  width: 90%;
  box-sizing: border-box;
  padding: 60px 40px 30px;
  background-color: #FFF;

  font-size: 18px;
  text-align: right;

  box-shadow: 0 0 0 2px #888;
}

.EditProfile button {
  grid-column: 2 span;
  justify-self: center;

  padding: 15px;
  border: none;
  background-color: #7BA9FE;

  font-weight: bold;
  font-size: 20px;
}

.EditProfile button:hover {
  background-color: #6E98E6;
}

.EditProfile input {
  width: 100%;
  border: none;

  box-shadow: 0 0 0 1px #888;

  font-size: 18px;
}

.EditProfile .goBack {
  position: absolute;
  top: 20px;
  left: 20px;

  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: #FFF url(arrow_left.png) no-repeat center / 30px 30px;

  font-size: 30px;
  box-shadow: 0 0 0 2px #000;
}

.EditProfile .goBack:hover {
  background-color: #DDD;
}

.EditProfile .goBack:focus {
  box-shadow: 0 0 0 2px #000, 0 0 0 4px #fff, 0 0 0 6px #000;
}

.EditProfile .goBack:focus-visible {
  outline: none;
}

@media (max-width: 600px) {
  .EditProfile {
    gap: 25px 10px;
    padding: 60px 20px 30px;
  }
}
